import { Chip, Typography, useMediaQuery } from '@mui/material'
import { addDays, format, subDays } from 'date-fns'
import { useState } from 'react'
import {
  ArrayField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateInput,
  ExportButton,
  FilterButton,
  FunctionField,
  ImageField,
  List,
  ListButton,
  Pagination,
  Show,
  SimpleList,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  usePermissions,
} from 'react-admin'
import { Link } from 'react-router-dom'
import PresignedImage from 'src/Components/PresignedImage'

import { datagridExporter, toChinese } from '../utils/utils'
import Business from './Business'

const ListActions = () => {
  const { total, isLoading } = useListContext()
  const { permissions } = usePermissions()
  return (
    <TopToolbar>
      <FilterButton />
      <ExportButton maxResults={50000} disabled={isLoading || total === 0} />
    </TopToolbar>
  )
}
export const RentList = ({ ...props }) => {
  const { permissions } = usePermissions()

  const isSmall = useMediaQuery((theme) =>
    (theme as any).breakpoints.down('lg')
  )

  const [onlyNotReturn, setOnlyNotReturn] = useState(false)

  const columns = [
    ...(permissions === 'admin' ? [<TextField key="id" source="id" />] : []),

    <DateField
      key="create_time"
      label="借用時間"
      source="create_time"
      showTime
    />,
    ...(permissions === 'admin' || permissions === 'manager'
      ? [
          <TextField
            key="store.store_name"
            label="店家"
            source="store.store_name"
          />,
        ]
      : []),
    <FunctionField
      key="user"
      label="使用者"
      render={(record) => record?.user?.username ?? ''}
    />,
    <TextField key="provider" label="使用途徑" source="provider" />,
    // <TextField key="rent_type" label="使用類型" source="rent_type" />,
    <TextField key="cup_type.name" label="杯款" source="cup_type.name" />,
    <TextField key="cup_id" label="杯子" source="cup_id" />,
    <FunctionField
      key="returns.store_name"
      sortable={false}
      label="歸還店家"
      source="returns"
      render={({ returns }) =>
        returns?.length > 0 && returns[0].store.store_name ? (
          returns[0].store.store_name
        ) : (
          <UnreturnChip />
        )
      }
    />,
    <FunctionField
      key="returns.create_time"
      sortable={false}
      label="歸還時間"
      source="returns"
      render={({ returns }) =>
        returns?.length > 0
          ? format(new Date(returns[0].create_time), 'yyyy-MM-dd HH:mm')
          : ''
      }
    />,
    <FunctionField
      key="returns.cup_id"
      sortable={false}
      label="歸還杯子"
      source="returns"
      render={({ returns }) =>
        returns?.length > 0 && typeof returns[0]?.cup_id === 'number'
          ? returns[0].cup_id
          : ''
      }
    />,
    // <FunctionField
    //   key="歸還照片"
    //   label="歸還照片"
    //   source="returns"
    //   render={({ returns }) =>
    //     returns?.length > 0 ? (
    //       <PresignedImage
    //         style={{
    //           width: '32px',
    //           height: '32px',
    //           objectFit: 'contain',
    //         }}
    //         filename={`returns/${returns[0].id}/image.jpg`}
    //       />
    //     ) : (
    //       ''
    //     )
    //   }
    // />,
  ]
  return (
    <List
      actions={<ListActions />}
      exporter={datagridExporter(columns)}
      title="掃描紀錄"
      {...props}
      sort={{ field: 'create_time', order: 'DESC' }}
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
      filterDefaultValues={{
        'create_time@_gte': format(subDays(new Date(), 14), 'yyyy-MM-dd'),
        'create_time@_lte': format(addDays(new Date(), 1), 'yyyy-MM-dd'),
      }}
      storeKey={false}
      filters={[
        <DateInput
          key="create_time@_gte"
          label="從"
          source="create_time@_gte"
          alwaysOn
        />,
        <DateInput
          key="create_time@_lte"
          label="到"
          source="create_time@_lte"
          alwaysOn
        />,
        // <FormControlLabel
        //   key="僅顯示未歸還"
        //   label="僅顯示未歸還"
        //   control={
        //     <Switch
        //       checked={onlyNotReturn}
        //       onChange={(e) => setOnlyNotReturn(e.target.checked)}
        //       // source="returns#id@_gt"
        //     />
        //   }
        // />,
        ...(permissions !== 'store'
          ? [
              <TextInput
                key="區域"
                label="區域"
                source="store#store_managements#staff#username@_ilike,store#store_managements#staff_id@_eq"
                alwaysOn
              />,
              <TextInput
                key="store"
                label="商店"
                source="store#store_name@_ilike"
                alwaysOn
              />,
              <TextInput
                key="self"
                label="自備"
                source="rent_type"
                defaultValue={'Self'}
              />,
            ]
          : []),
        <BooleanInput
          key="僅顯示未歸還"
          label="僅顯示未歸還"
          source="returns_aggregate#count#predicate@_eq"
          parse={(v) => (v ? 0 : null)}
          format={(v) => (v === 0 ? true : null)}
          alwaysOn
          sx={{
            marginBottom: 1,
          }}
        />,
      ]}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => {
            if (permissions === 'admin') {
              return `#${record.id} ${record.store.store_name} `
            }
          }}
          secondaryText={(record) => {
            const returnInfo =
              record.returns.length === 0 ? null : record.returns[0]
            return (
              <div>
                <Typography variant="body2">
                  {format(new Date(record.create_time), 'yyyy-MM-dd HH:mm')}
                </Typography>
                <Typography variant="body2">
                  使用者：{record.user?.username}
                </Typography>
                <Typography variant="body2">
                  杯款：{record?.cup_type?.name}
                </Typography>
                {/* {permissions === 'admin' && ( */}
                {record.returns.length === 0 ? (
                  <div
                    style={{
                      marginTop: 8,
                    }}
                  >
                    <UnreturnChip />
                  </div>
                ) : (
                  <>
                    <Typography variant="body2">
                      歸還地點 {returnInfo ? returnInfo.store.store_name : '➖'}
                    </Typography>
                    <Typography variant="body2">
                      歸還時間{' '}
                      {returnInfo
                        ? format(
                            new Date(returnInfo.create_time),
                            'yyyy-MM-dd HH:mm'
                          )
                        : '➖'}
                    </Typography>
                  </>
                )}

                {/* )} */}
              </div>
            )
          }}
          tertiaryText={(record) => `${toChinese[record.rent_type]}`}
          linkType={'show'}
        />
      ) : (
        <Datagrid bulkActionButtons={false} rowClick="show">
          {columns}
        </Datagrid>
      )}
    </List>
  )
}
const UnreturnChip = () => (
  <Chip variant="outlined" size="small" color="warning" label="⚠️未歸還" />
)
const RentCreateActions = () => (
  <TopToolbar>
    <ListButton />
  </TopToolbar>
)

export const RentCreate = (props) => (
  <Create actions={<RentCreateActions />} {...props}>
    <Business />
  </Create>
)

const RentShowActions = () => (
  <TopToolbar>
    <ListButton />
  </TopToolbar>
)

export const RentShow = ({ ...props }) => {
  const { permissions } = usePermissions()

  return (
    <Show actions={<RentShowActions />} {...props}>
      <SimpleShowLayout>
        {permissions === 'admin' && <TextField source="id" />}

        {permissions === 'admin' && <TextField source="store.store_name" />}
        <FunctionField
          label="使用者"
          render={(record) => (
            <>
              {permissions === 'admin' ? (
                <Link to={`/user/${record.user_id}/show`}>
                  {record?.user?.username}
                </Link>
              ) : (
                record?.user?.username
              )}

              <p>{record?.user?.email}</p>
              <p>{record?.user?.phone}</p>
            </>
          )}
        />
        <TextField label="使用途徑" source="provider" />
        <TextField label="使用類型" source="rent_type" />
        <TextField label="杯子id" source="cup_id" />
        <DateField source="create_time" showTime />
        {/* <BooleanField label="狀態" source="active" /> */}

        {permissions === 'admin' && (
          <ArrayField label="歸還訊息" source="returns">
            <Datagrid bulkActionButtons={false}>
              <TextField label="歸還店家" source="store.store_name" />
              <DateField label="歸還時間" source="create_time" showTime />
              <ImageField source="store.image_url" />
              <TextField label="杯子id" source="cup_id" />
              <FunctionField
                label="歸還照片"
                render={(record) => (
                  <PresignedImage
                    style={{
                      width: '200px',
                      height: '200px',
                      objectFit: 'contain',
                    }}
                    filename={`returns/${record.id}/image.jpg`}
                  />
                )}
              />
            </Datagrid>
          </ArrayField>
        )}
      </SimpleShowLayout>
    </Show>
  )
}
