const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

const EmbedGoogleMap = ({ q, lat, lng, zoom = 15 }) => {
  const embedUrl = `https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=${q}&center=${lat},${lng}&zoom=${zoom}`

  return (
    <div style={{ width: '100%', height: '400px' }}>
      <iframe
        title="Google Map"
        width="100%"
        height="100%"
        frameBorder="0"
        style={{ border: 0 }}
        src={embedUrl}
        allowFullScreen
      ></iframe>
    </div>
  )
}

export default EmbedGoogleMap
