import DeleteIcon from '@mui/icons-material/Delete'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { FC, useState } from 'react'
import {
  ArrayField,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditProps,
  FunctionField,
  List,
  NullableBooleanInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useDelete,
  useNotify,
  usePermissions,
  useRefresh,
} from 'react-admin'
import { Role } from 'src/authProvider'
import SmallImageField from 'src/Components/SmallImageField'
import { xlsxExporter } from 'src/utils/utils'

const t = {
  [Role.MANAGER]: '區域管理員',
  [Role.LOGISTICS]: '物流士',
}
export const StaffList = ({ permissions = undefined, ...props }) => {
  return (
    <List
      exporter={xlsxExporter}
      bulkActionButtons={false}
      title="人員"
      {...props}
      sort={{ field: 'create_time', order: 'DESC' }}
    >
      <Datagrid bulkActionButtons={false} rowClick="edit">
        <FunctionField
          label="角色"
          source="role"
          render={(record) => {
            return t[record?.role]
          }}
        />
        <TextField source="username" />
        <TextField source="phone" />
        <DateField source="create_time" showTime />
        <TextField
          sortable={false}
          label="管理店家數量"
          source="store_managements_aggregate.aggregate.count"
        />
      </Datagrid>
    </List>
  )
}

export const StaffEdit: FC<EditProps> = (props) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const [deleteOne, { isLoading, error }] = useDelete()
  const handleDeleteStaffClick = (record) => {
    if (!record.id) {
      notify('error')
      return
    }
    deleteOne(
      'store_management',
      { id: record.id },
      {
        onSuccess: () => {
          notify('刪除成功！')
          refresh()
        },
      }
    )
  }
  const { permissions } = usePermissions()

  return (
    <Edit redirect={false} {...props}>
      <SimpleForm>
        <TextInput source="username" />
        <NullableBooleanInput
          fullWidth
          nullLabel="預設"
          label="允許杯數負數"
          source="allow_minus_cup_num"
          helperText="如果店家有設定會優先採用店家，如果店家沒有設定會採用全局設定"
        />
        <ArrayField label="管理店家" source="store_managements">
          <Datagrid bulkActionButtons={false}>
            <SmallImageField source="store.image_url" />
            <TextField label="店家" source="store.store_name" />
            <DateField label="時間" source="create_time" showTime />

            <FunctionField
              label="Action"
              render={(record) => {
                return (
                  <StaffMoreAction
                    permissions={permissions}
                    onDeleteClick={() => handleDeleteStaffClick(record)}
                  />
                )
              }}
            />
          </Datagrid>
        </ArrayField>
      </SimpleForm>
    </Edit>
  )
}
function StaffMoreAction({ permissions, onDeleteClick }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {permissions === 'admin' && (
          <MenuItem
            onClick={() => {
              handleClose()
              onDeleteClick()
              // Add your delete logic here
            }}
          >
            <DeleteIcon />
            Delete
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

export const StaffCreate = (props) => {
  return (
    <Create {...props} resource="staffs/signup" redirect="/staff">
      <SimpleForm>
        <SelectInput
          source="role"
          choices={[
            { id: Role.MANAGER, name: t[Role.MANAGER] },
            { id: Role.LOGISTICS, name: t[Role.LOGISTICS] },
          ]}
        />
        <TextInput source="username" />
        <TextInput source="phone" />
        <TextInput source="password" />
        <TextInput source="confirm" />
      </SimpleForm>
    </Create>
  )
}
