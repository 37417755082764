import { ApolloProvider } from '@apollo/client'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import CupIcon from '@mui/icons-material/EmojiFoodBeverage'
import ListAltIcon from '@mui/icons-material/ListAlt'
import LoyaltyIcon from '@mui/icons-material/Loyalty'
import NotificationsIcon from '@mui/icons-material/Notifications'
import PeopleIcon from '@mui/icons-material/People'
import StoreIcon from '@mui/icons-material/Store'
// import MyUserMenu from './MyUserMenu'
import { Collapse, ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import { createBrowserHistory as createHistory } from 'history'
import { FC, useState } from 'react'
import {
  Admin,
  AppBar,
  CustomRoutes,
  Layout,
  Menu,
  Resource,
  Sidebar,
  usePermissions,
} from 'react-admin'
import { Route } from 'react-router-dom'
import useDataProvider from 'src/dataProvider'
import { myApolloClientWithAuth } from 'src/dataProvider/hasuraDataProvider'

import authProvider, { Role } from '../authProvider'
import i18nProvider from '../i18nProvider'
import theme from '../theme'
import { BonusList } from './bonuses'
import Box from './Box'
import BoxWithDevice from './BoxWithDevice'
import Business from './Business'
import { CouponCreate, CouponEdit, CouponList } from './coupons'
import { CupNumRecordList } from './cup-num-records'
import { CupTypeCreate, CupTypeEdit, CupTypeList } from './cup-types'
import {
  CupWashedRecordCreate,
  CupWashedRecordList,
} from './cup-washed-records'
import { CupCreate, CupEdit, CupList } from './cups'
import Dashboard from './dashboards/Dashboard'
import LoadingPage from './LoadingPage'
import { MachineEdit, MachineList } from './machines'
import MyLoginPage from './MyLoginPage'
import { OrderList } from './order'
import { ProductOrderList, ProductOrderShow } from './product_orders'
import { ProductCreate, ProductEdit, ProductList } from './products'
import { ProfileEdit } from './profile'
import { RentList, RentShow } from './rents'
import { ReturnList, ReturnShow } from './returns'
import { StaffCreate, StaffEdit, StaffList } from './staffs'
import {
  StoreManagementCreate,
  StoreManagementEdit,
  StoreManagementList,
} from './store_managements'
import { StoreNotifyCreate, StoreNotifyList } from './store-notifies'
import { StoreCreate, StoreEdit, StoreList } from './stores'
import { UserEdit, UserList } from './users'
const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  appBar: {},
})

const MyAppBar = (props) => {
  const classes = useStyles()

  return (
    <AppBar
      sx={{
        color: 'white',
      }}
      color="primary"
      className={classes.appBar}
      {...props}
    >
      <Typography
        variant="h6"
        className={classes.title}
        id="react-admin-title"
      />
    </AppBar>
  )
}

const MySidebar = (props) => (
  <Sidebar
    sx={{
      '& .RaSidebar-drawerPaper': {
        backgroundColor: 'red',
      },
    }}
    {...props}
  />
)
const NestList: FC<any> = ({ title, icon: Icon = CupIcon, children }) => {
  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(!open)
  }
  return (
    <>
      <MenuItem onClick={handleClick}>
        <ListItemIcon sx={{ color: 'black' }}>
          <Icon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{title}</ListItemText>
        {/* {open ? <ExpandLess /> : <ExpandMore />} */}
      </MenuItem>
      <Collapse sx={{ pl: 2 }} in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  )
}

const MyMenu = () => {
  const { isLoading, permissions } = usePermissions()
  return (
    <Menu>
      <Menu.DashboardItem />
      {permissions === 'admin' && [
        <Menu.ResourceItem key="user" name="user" />,
        <Menu.ResourceItem key="store" name="store" />,
        <NestList key="借還杯" title="借還杯" icon={ListAltIcon}>
          <Menu.ResourceItem name="rent" />
          <Menu.ResourceItem name="return" />
        </NestList>,
        <NestList key="杯子" title="杯子" icon={CupIcon}>
          <Menu.ResourceItem name="cup" />
          <Menu.ResourceItem name="cup_type" />
          <Menu.ResourceItem name="cup_washed_record" />
          <Menu.ResourceItem name="cup_num_record" />
        </NestList>,
        <NestList key="優惠" title="優惠" icon={LoyaltyIcon}>
          <Menu.ResourceItem name="bonus" />
          <Menu.ResourceItem name="product" />
          <Menu.ResourceItem name="coupon" />
        </NestList>,
        <NestList key="人員" title="人員" icon={PeopleIcon}>
          <Menu.ResourceItem name="staff" />
          {/* <Menu.ResourceItem name="store_management" /> */}
          <Menu.ResourceItem name="store_notify" />
        </NestList>,
        <NestList key="電商" title="電商" icon={AttachMoneyIcon}>
          <Menu.ResourceItem name="product_order" />
          <Menu.ResourceItem name="order" />
        </NestList>,
        <Menu.ResourceItem key="machine" name="machine" />,
      ]}

      <div></div>

      {permissions !== 'admin' && <Menu.ResourceItems />}
    </Menu>
  )
}

const MyLayout = (props) => (
  <Layout {...props} appBar={MyAppBar} sidebar={MySidebar} menu={MyMenu} />
)
const MyCatchAll = () => {
  return <small>{window.location.href}</small>
}
const history = createHistory()

function AdminPage() {
  const { dataProvider, loading, error } = useDataProvider()

  if (loading) {
    return <LoadingPage />
  }

  if (error || dataProvider === undefined) {
    // Prevent old jwt token which does not contain hasura claim.
    localStorage.removeItem('token')
    return (
      <div>
        發生了一些問題，請重新登入，或請聯絡客服
        <pre>{JSON.stringify(error, null, 2)}</pre>
        <a href="/login">登入</a>
      </div>
    )
  }

  return (
    <ApolloProvider client={myApolloClientWithAuth}>
      <Admin
        theme={theme}
        layout={MyLayout}
        dashboard={Dashboard}
        loginPage={MyLoginPage}
        authProvider={authProvider}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        catchAll={MyCatchAll}
        history={history}
      >
        {(permissions) => (
          <>
            <CustomRoutes>
              <Route key="profile" path="/profile" element={<ProfileEdit />} />
            </CustomRoutes>
            {(function () {
              if (permissions === 'admin')
                return (
                  <>
                    <Resource
                      options={{ label: '使用者' }}
                      name="user"
                      list={UserList}
                      edit={UserEdit}
                      icon={PeopleIcon}
                    />
                    {/* // Please use user to check bonus.
                          // <Resource
                          //   key="bonus"
                          //   options={{ label: '點數' }}
                          //   name="bonus"
                          //   list={BonusList}
                          //   icon={PeopleIcon}
                          // />,
                          // Please use user to check social.
                          // <Resource
                          //   key="social_connection"
                          //   options={{ label: '社群使用者' }}
                          //   name="social_connection"
                          //   list={SocialUserList}
                          //   icon={RecentActorsIcon}
                          // />, */}
                    <Resource
                      options={{ label: '借用紀錄' }}
                      name="rent"
                      list={RentList}
                      show={RentShow}
                      icon={ListAltIcon}
                    />
                    <Resource
                      options={{ label: '歸還紀錄' }}
                      name="return"
                      list={ReturnList}
                      show={ReturnShow}
                      icon={ListAltIcon}
                    />
                    <Resource
                      options={{ label: '店家' }}
                      name="store"
                      list={StoreList}
                      edit={StoreEdit}
                      create={StoreCreate}
                      icon={StoreIcon}
                      recordRepresentation="store_name"
                    />
                    <Resource
                      options={{ label: '物流收送紀錄' }}
                      name="cup_num_record"
                      list={CupNumRecordList}
                      icon={StoreIcon}
                    />
                    <Resource
                      options={{ label: '杯子' }}
                      name="cup"
                      list={CupList}
                      edit={CupEdit}
                      create={CupCreate}
                      icon={CupIcon}
                    />
                    <Resource
                      options={{ label: '杯款' }}
                      name="cup_type"
                      list={CupTypeList}
                      edit={CupTypeEdit}
                      create={CupTypeCreate}
                      icon={CupIcon}
                    />
                    <Resource
                      options={{ label: '杯子清洗紀錄' }}
                      name="cup_washed_record"
                      list={CupWashedRecordList}
                      create={CupWashedRecordCreate}
                      icon={ListAltIcon}
                    />
                    <Resource
                      options={{ label: '獎勵' }}
                      name="product"
                      list={ProductList}
                      edit={ProductEdit}
                      create={ProductCreate}
                      icon={LoyaltyIcon}
                    />
                    <Resource
                      options={{ label: '點數紀錄' }}
                      name="bonus"
                      list={BonusList}
                    />
                    <Resource
                      options={{ label: '優惠碼' }}
                      name="coupon"
                      create={CouponCreate}
                      edit={CouponEdit}
                      list={CouponList}
                      icon={LoyaltyIcon}
                    />
                    {/* <Resource
                      options={{ label: '訂閱紀錄' }}
                      name="subscription"
                      list={SubscriptionList}
                    /> */}
                    <Resource
                      options={{ label: '訂單紀錄' }}
                      name="order"
                      list={OrderList}
                    />
                    <Resource
                      options={{ label: '區域管理者' }}
                      name="staff"
                      list={StaffList}
                      edit={StaffEdit}
                      create={StaffCreate}
                      recordRepresentation="username"
                    />
                    <Resource
                      options={{ label: '店家權限管理' }}
                      name="store_management"
                      list={StoreManagementList}
                      edit={StoreManagementEdit}
                      create={StoreManagementCreate}
                    />
                    <Resource name="record/rent_count_by_day" />
                    <Resource
                      options={{ label: '電商訂單紀錄' }}
                      name="product_order"
                      list={ProductOrderList}
                      show={ProductOrderShow}
                    />
                    <Resource
                      options={{ label: 'LINE 店家通知' }}
                      name="store_notify"
                      create={StoreNotifyCreate}
                      list={StoreNotifyList}
                      icon={NotificationsIcon}
                    />
                    <Resource
                      options={{ label: '機器' }}
                      name="machine"
                      edit={MachineEdit}
                      list={MachineList}
                      icon={NotificationsIcon}
                    />
                    {/* <Resource key="profile" name="profile" /> */}
                  </>
                )
              else if (permissions === 'store') {
                return (
                  <>
                    <Resource
                      options={{ label: '手動借還' }}
                      name="business"
                      list={Business}
                    />
                    <Resource
                      options={{ label: '感應模式' }}
                      name="box"
                      list={Box}
                    />
                    <Resource
                      options={{ label: '讀卡機模式' }}
                      name="BoxWithDevice"
                      list={BoxWithDevice}
                    />
                    <Resource
                      options={{ label: '掃描紀錄' }}
                      name="rent"
                      list={RentList}
                      show={RentShow}
                      // create={RentCreate}
                      icon={ListAltIcon}
                    />
                    {/* <Resource
                      options={{ label: '物流收送紀錄' }}
                      name="cup_num_record"
                      list={CupNumRecordList}
                      icon={ListAltIcon}
                    /> */}
                    {/* <Resource options={{ label: '優惠提供' }} name="stores/products" list={ProductList} /> */}
                    {/* <Resource key="profile" name="profile" /> */}
                    {/* <Resource
                      options={{ label: '統計' }}
                      name="statistics"
                      list={BusinessDashboard}
                    /> */}
                    ,{/* for statistics */}
                    {/* <Resource
                      options={{ label: '第一次使用人數' }}
                      name="record/get_first_users_in_store"
                    /> */}
                    <Resource name="record/rent_count_by_day" />
                    <Resource
                      options={{ label: 'LINE 店家通知' }}
                      name="store_notify"
                      create={StoreNotifyCreate}
                      list={StoreNotifyList}
                      icon={NotificationsIcon}
                    />
                  </>
                )
              } else if (permissions === Role.MANAGER) {
                return (
                  <>
                    <Resource
                      options={{ label: '店家' }}
                      name="store"
                      list={StoreList}
                      edit={StoreEdit}
                      create={StoreCreate}
                      icon={StoreIcon}
                    />
                    <Resource
                      options={{ label: '借用紀錄' }}
                      name="rent"
                      list={RentList}
                      show={RentShow}
                      icon={ListAltIcon}
                    />
                    <Resource
                      options={{ label: '歸還紀錄' }}
                      name="return"
                      list={ReturnList}
                      show={ReturnShow}
                      icon={ListAltIcon}
                    />
                    <Resource
                      options={{ label: '物流收送紀錄' }}
                      name="cup_num_record"
                      list={CupNumRecordList}
                      icon={StoreIcon}
                    />
                    <Resource
                      options={{ label: '點數紀錄' }}
                      name="bonus"
                      list={BonusList}
                    />
                    <Resource name="record/rent_count_by_day" />
                    {/* <Resource key="profile" name="profile" /> */}
                    <Resource
                      options={{ label: 'LINE 店家通知' }}
                      name="store_notify"
                      create={StoreNotifyCreate}
                      list={StoreNotifyList}
                      icon={NotificationsIcon}
                    />
                    <Resource
                      options={{ label: '設定' }}
                      name="staff"
                      edit={StaffEdit}
                      recordRepresentation="username"
                    />
                  </>
                )
              } else if (permissions === Role.LOGISTICS) {
                return (
                  <>
                    <Resource
                      options={{ label: '物流收送紀錄' }}
                      name="cup_num_record"
                      list={CupNumRecordList}
                      icon={StoreIcon}
                    />
                    <Resource
                      options={{ label: '店家' }}
                      name="store"
                      list={StoreList}
                      edit={StoreEdit}
                      icon={StoreIcon}
                    />
                  </>
                )
              }
              return null
            })()}
          </>
        )}
      </Admin>
    </ApolloProvider>
  )
}

export default AdminPage
