import { Typography } from '@mui/material'

export default function NumberChange({ number, change }) {
  return (
    <>
      {number}
      {' ('}
      <Typography
        component="span"
        sx={{
          fontSize: 'inherit',
          color: change > 0 ? 'success.main' : 'inherit',
        }}
      >
        {change > 0 ? '+' : ''}
        {change}
      </Typography>
      {')'}
    </>
  )
}
