import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import gql from 'graphql-tag'
import jwt_decode from 'jwt-decode'
import buildHasuraProvider, { buildFields } from 'ra-data-hasura'
import { useEffect, useState } from 'react'
import { DataProvider } from 'react-admin'

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')
  if (token === null) {
    return undefined
  }
  try {
    const profile = jwt_decode(token)
    if (!profile?.role) {
      throw Error('Client Error: no role in jwt')
    }
    return {
      headers: {
        ...headers,
        'x-hasura-role': profile.role,
        Authorization: `Bearer ` + token,
      },
    }
  } catch (error) {
    localStorage.removeItem('token')
    throw Error('Client Error: jwt_decode error' + JSON.stringify(error))
  }
})

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_HASURA_URL,
})

export const myApolloClientWithAuth = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

// Define the additional fields that we want.
// Note: This is a temp way.
const extendedQueries = [
  {
    resouceName: 'user',
    fetchType: 'GET_LIST',
    query: gql`
      {
        social_connections {
          access_token
        }
      }
    `,
  },
  {
    resouceName: 'user',
    fetchType: 'GET_ONE',
    query: gql`
      {
        social_connections {
          access_token
          provider
        }
        bonuses(limit: 100, order_by: { create_time: desc }) {
          change
          info
          create_time
          expired_time
        }
        rents(limit: 100, order_by: { create_time: desc }) {
          create_time
          provider
          cup_type {
            id
            name
          }
          rent_type
          state
          store {
            store_name
          }
          returns {
            store {
              store_name
            }
            create_time
          }
        }
      }
    `,
  },
  {
    resouceName: 'rent',
    fetchType: 'GET_LIST',
    query: gql`
      {
        user {
          username
        }
        cup_type {
          id
          name
        }
        store {
          store_name
        }
        returns {
          id
          store {
            store_name
          }
          create_time
          cup_id
        }
      }
    `,
  },
  {
    resouceName: 'rent',
    fetchType: 'GET_ONE',
    query: gql`
      {
        user {
          id
          username
          phone
          email
        }
        store {
          store_name
        }
        returns {
          id
          store {
            store_name
          }
          create_time
          cup_id
        }
      }
    `,
  },
  {
    resouceName: 'return',
    fetchType: 'GET_LIST',
    query: gql`
      {
        store {
          store_name
        }
        rent {
          user_id
          provider
        }
      }
    `,
  },
  {
    resouceName: 'cup_num_record',
    fetchType: 'GET_LIST',
    query: gql`
      {
        cup_num {
          cup_type {
            name
          }
          store {
            store_name
            store_managements {
              staff_id
            }
          }
        }
      }
    `,
  },
  {
    resouceName: 'store',
    fetchType: 'GET_LIST',
    query: gql`
      {
        cup_nums {
          cup_type {
            name
            image_url
          }
          clean_number
          dirty_number
        }
        store_managements {
          staff {
            username
          }
        }
      }
    `,
  },
  {
    resouceName: 'store',
    fetchType: 'GET_ONE',
    query: gql`
      {
        cup_nums(order_by: { cup_type: { create_time: desc } }) {
          id
          cup_type_id
          cup_type {
            id
            name
            image_url
          }
          clean_number
          dirty_number
        }
        cup_num_records(limit: 6, order_by: { create_time: desc }) {
          cup_num {
            cup_type {
              name
              image_url
            }
          }
          create_time
          clean_number
          dirty_number
          clean_number_change
          dirty_number_change
          note
        }
        store_managements {
          id
          create_time
          staff {
            id
            username
            create_time
            allow_minus_cup_num
          }
        }
      }
    `,
  },
  {
    resouceName: 'bonus',
    fetchType: 'GET_LIST',
    query: gql`
      {
        user {
          username
        }
        product {
          product_name
        }
      }
    `,
  },
  {
    resouceName: 'cup',
    fetchType: 'GET_ONE',
    query: gql`
      {
        cup_washed_records(limit: 20, order_by: { create_time: desc }) {
          create_time
          note
        }
        cup_washed_records_aggregate {
          aggregate {
            count
          }
        }
        rents(order_by: { create_time: desc }) {
          id
          create_time
          store {
            store_name
          }
        }
        returns(order_by: { create_time: desc }) {
          id
          create_time
          store {
            store_name
          }
        }
      }
    `,
  },
  {
    resouceName: 'cup_type',
    fetchType: 'GET_LIST',
    query: gql`
      {
        # cups_aggregate {
        #   aggregate {
        #     count
        #   }
        # }
        cup_nums_aggregate {
          aggregate {
            sum {
              clean_number
              dirty_number
            }
          }
        }
      }
    `,
  },

  {
    resouceName: 'cup_num',
    fetchType: 'GET_LIST',
    query: gql`
      {
        cup_type {
          id
          name
          image_url
        }
      }
    `,
  },
  {
    resouceName: 'cup_type',
    fetchType: 'GET_ONE',
    query: gql`
      {
        cups(limit: 100, order_by: { create_time: desc }) {
          id
          create_time
          total_washed_times
        }
      }
    `,
  },
  {
    resouceName: 'subscription',
    fetchType: 'GET_LIST',
    query: gql`
      {
        user {
          username
        }
        store {
          store_name
        }
      }
    `,
  },
  {
    resouceName: 'order',
    fetchType: 'GET_LIST',
    query: gql`
      {
        user {
          username
        }
        store {
          store_name
        }
      }
    `,
  },
  {
    resouceName: 'staff',
    fetchType: 'GET_LIST',
    query: gql`
      {
        store_managements_aggregate {
          aggregate {
            count
          }
        }
      }
    `,
  },
  {
    resouceName: 'staff',
    fetchType: 'GET_ONE',
    query: gql`
      {
        store_managements {
          id
          store {
            id
            store_name
            image_url
          }
        }
      }
    `,
  },
  {
    resouceName: 'store_management',
    fetchType: 'GET_LIST',
    query: gql`
      {
        staff {
          username
        }
        store {
          store_name
        }
      }
    `,
  },
  {
    resouceName: 'product',
    fetchType: 'GET_LIST',
    query: gql`
      {
        store {
          store_name
        }
        inventories {
          number
        }
      }
    `,
  },
  {
    resouceName: 'product_order',
    fetchType: 'GET_LIST',
    query: gql`
      {
        user {
          username
        }
        product_order_items {
          product_id
          product {
            product_name
          }
          price
          quantity
        }
      }
    `,
  },
  {
    resouceName: 'product_order',
    fetchType: 'GET_ONE',
    query: gql`
      {
        user {
          username
        }
        product_order_items {
          product_id
          product {
            product_name
          }
          price
          quantity
        }
      }
    `,
  },

  {
    resouceName: 'store_notify',
    fetchType: 'GET_LIST',
    query: gql`
      {
        store {
          store_name
        }
        staff {
          username
        }
      }
    `,
  },
]

/**
 * Extracts just the fields from a GraphQL AST.
 * @param {GraphQL AST} queryAst
 */
const extractFieldsFromQuery = (queryAst) => {
  return queryAst.definitions[0].selectionSet.selections
}

const customBuildFields = (type, fetchType) => {
  const resourceName = type.name
  // First take the default fields (all, but no related or nested).
  const defaultFields = buildFields(type, fetchType)

  // Extend other queries for other resources/fetchTypes here...
  const matchedQuery = extendedQueries.find(
    (q) => q.resouceName === resourceName && q.fetchType === fetchType
  )
  if (matchedQuery) {
    const relatedEntities = extractFieldsFromQuery(matchedQuery.query)
    defaultFields.push(...relatedEntities)
  }

  return defaultFields
}

const useHasuraDataProvider = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<unknown>(undefined)

  const [dataProvider, setDataProvider] = useState<
    DataProvider<string> | undefined
  >(undefined)
  useEffect(() => {
    const buildDataProvider = async () => {
      setLoading(true)

      try {
        const dataProvider = await buildHasuraProvider(
          {
            client: myApolloClientWithAuth,
          },
          { buildFields: customBuildFields }
        )
        setDataProvider(() => dataProvider)
      } catch (error) {
        setError(error)
      }
      setLoading(false)
    }
    buildDataProvider()
  }, [])

  return { dataProvider, loading, error }
}

export default useHasuraDataProvider
